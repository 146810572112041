<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-domain</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Feature Profiles ({{ totalProfiles }})</v-toolbar-title
      >
    </v-app-bar>
    <div
      class="mx-4"
      id="building-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-btn
          color="primary"
          class="appbar__btn"
          height="40px"
          :to="{ name: 'feature-profile.create' }"
        >
          <v-icon class="mr-2">{{ icons.add }}</v-icon>
          Add Profile
        </v-btn>
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name or address"
          @input="search"
        ></v-text-field>
        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-10" color="primary" dark v-bind="attrs" v-on="on">
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </div>
      <v-data-table
        :headers="headers"
        :items="profiles"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalProfiles"
        @click:row="editItem"
        @update:options="fetch"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.is_active="{ item }">
          <v-checkbox v-model="item.is_active" readonly hide-details />
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(profiles.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import debounce from 'lodash/debounce'

export default {
  name: 'ProfilesPage',
  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'name',
      sortOrder: true,
      headers: [
        {
          text: 'Profile Name',
          value: 'name',
        },
        {
          text: 'Building(s)',
          value: 'buildingName',
        },
        {
          text: 'Active',
          value: 'is_active',
        },
        {
          text: 'Added Date',
          value: 'addedDate',
        },
        {
          text: 'Created By',
          value: 'ownerName',
        },
      ],
      selected: [],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
    }
  },

  created() {
    this.clearProfiles()
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      profiles: (state) => state.profile.list,
      totalProfiles: (state) => state.profile.totalProfiles,
      paramsProfile: (state) => state.profile.queryParams,
      scrollPosition: (state) => state.profile.scrollPosition,
    }),
  },

  methods: {
    ...mapActions({
      getProfiles: 'profile/getProfiles',
    }),

    ...mapMutations({
      clearProfiles: 'profile/clearProfileList',
      setParams: 'profile/setQueryParams',
      setScrollPosition: 'profile/setScrollPosition',
    }),

    search: debounce(function () {
      this.clearProfiles()
      this.fetch()
    }, 600),

    fetchInitParams() {
      if (this.paramsProfile?.sort) {
        if (this.paramsProfile.sort[0].includes('-')) {
          this.defaultFilter = this.paramsProfile.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsProfile?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'name'
      }

      if (this.paramsProfile?.search) {
        this.filter.search = this.paramsProfile.search
      }

      this.fetch(null, 1, this.paramsProfile)
    },

    activeAction() {
      this.clearProfiles()
      this.fetch()
    },

    async fetch(options, page = 1, profileParams = null) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalProfiles
            : options.itemsPerPage
          : 25,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getProfiles(profileParams || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
      this.setParams(params)
    },

    editItem(item) {
      this.$router.push({
        name: 'feature-profile.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return

      const scrollPosition = this.scrollPosition
      this.setScrollPosition(0)

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__status {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__status {
      margin-bottom: 15px;
    }

    &__status,
    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
