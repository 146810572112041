<template>
  <div class="maintenance_doughnut">
    <div style="padding-bottom: 15px !important">
      <h4 class="section-title">Active Maintenance Requests</h4>
    </div>
    <div class="active-maintenance-chart">
      <Doughnut
        v-if="loaded"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :borderWidth="0"
        :responsive="true"
        :maintainAspectRatio="false"
      />
      <div class="descriptions" v-if="loaded">
        <div class="counts">
          <div class="count-item new-count">
            {{ totalMaintenanceStatusNews }}
          </div>
          <div class="count-item inprogress-count">
            {{ totalMaintenanceStatusInprogress }}
          </div>
        </div>
      </div>
    </div>

    <div class="div__button_see_all">
      <v-btn class="button_see_all" @click="seeAll" text>
        see all <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import { mapState, mapActions, mapMutations } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import FilterTableMixin from '@/utils/mixins/FilterTable'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'DoughnutChart',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },

    cssClasses: {
      default: 'chart',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultFilter: 'created_at',
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              // Add padding here
              padding: 20,
            },
          },
        },
      },
      loaded: false,
    }
  },

  async created() {
    await this.fetch()
  },

  computed: {
    ...mapState({
      totalMaintenanceStatusNews: (state) =>
        state.maintenance.totalMaintenanceStatusNews,
      totalMaintenanceStatusInprogress: (state) =>
        state.maintenance.totalMaintenanceStatusInprogress,
    }),
  },

  methods: {
    ...mapActions({
      getMaintenanceStatusNews: 'maintenance/getMaintenanceStatusNews',
      getMaintenanceStatusInProgress:
        'maintenance/getMaintenanceStatusInProgress',
    }),

    ...mapMutations({
      clearMaintenances: 'maintenance/clearMaintenanceList',
    }),

    filterList(filterValue) {
      this.clearMaintenances()
      this.fetch(null, filterValue)
    },

    reloadGraph() {
      this.chartData = {
        labels: ['New', 'In-Progress'],
        datasets: [
          {
            backgroundColor: ['#6936C0', '#376FDB'],
            data: [
              this.totalMaintenanceStatusNews,
              this.totalMaintenanceStatusInprogress,
            ],
          },
        ],
      }
    },

    async fetch() {
      await this.getMaintenanceStatusInProgress({})
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {})
      await this.getMaintenanceStatusNews({})
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loaded = true
          this.reloadGraph()
        })
    },

    seeAll() {
      // this.$router.push({
      //   name: 'maintenance.dashboard',
      // })
      const routeData = this.$router.resolve({ name: 'maintenance.dashboard' })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.maintenance_doughnut .title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}
.div__button_see_all {
  text-align: right;
}
.button_see_all {
  text-transform: uppercase !important;
  color: #1976d2 !important;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  bottom: 0; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
}
.active-maintenance-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .descriptions {
    margin-top: -28px;

    .counts {
      display: flex;
      justify-content: center;

      .count-item {
        width: 80px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
      }
    }
  }
}

.chart {
  display: flex !important;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding: 20px;
}
</style>
